// import js dependencies

//swiper js
import Swiper from 'swiper/bundle';
//swiper css
import 'swiper/css/bundle';

jQuery(function($) {
	//menu
	$('body').on('click','.mobileMenu',function(){
		$('.menu .content').show();
		$('#page').toggleClass('mobileOpen');
		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');

		$(this).toggleClass('open');
	});


	//close menu for desktop if resize is bigger than breakpoint
	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 1200) {
			
			$('.menu .content.open').hide();

			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
			
		}
    })
	

	//accordion on team section
	$('body').on('click', '.team .accordion .accordion-button', function(event){
		event.preventDefault();
		if($(this).hasClass('collapsed')){
			$(this).html('READ MORE')
		}else{
			$(this).html('READ LESS')
		}
	});


	//header slider
	const headerSwiper = new Swiper("#headerSwiper.slider", {
        slidesPerView: 1,
		autoplay: true,
		effect: 'fade',
		loop: true,
		delay: 2000,
		speed: 1000,
    });


	//testimonial slider
	const testimonialSlider = document.querySelectorAll('.testimonialSlider-wrapper');
	var i = 0;

	for( i=0; i< testimonialSlider.length; i++ ) {

		var first = testimonialSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		testimonialSlider[i].classList.add('testimonialSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('testimonialSlider-button-next-' + i); //next
		third.classList.add('testimonialSlider-button-prev-' + i); //previous

		var slider = new Swiper('.testimonialSlider-wrapper-' + i, {
			speed: 1000,
			slidesPerView: 1,
			spaceBetween: 30,
			direction: 'horizontal',
			loop: true,
			autoplay: false,
			navigation: {
				nextEl: '.testimonialSlider-button-next-' + i,
				prevEl: '.testimonialSlider-button-prev-' + i,
			},
		});

	}


	//carousel
	const iconsSlider = document.querySelectorAll('.iconsSlider-wrapper');
	var i = 0;

	for( i=0; i< iconsSlider.length; i++ ) {

		var spacing = 110;

		var first = iconsSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		iconsSlider[i].classList.add('iconsSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('iconsSlider-button-next-' + i); //next
		third.classList.add('iconsSlider-button-prev-' + i); //previous

		var slider = new Swiper('.iconsSlider-wrapper-' + i, {
			speed: 1000,
			// slidesPerView: perView,
			spaceBetween: spacing,
			direction: 'horizontal',
			loop: true,
			// autoplay: {
			// 	delay: 1000,
			// },
			navigation: {
				nextEl: '.iconsSlider-button-next-' + i,
				prevEl: '.iconsSlider-button-prev-' + i,
			},
			breakpoints: {
				// when window width is >= 320px
				1200: {
					slidesPerView: 3,
				},
				991: {
					slidesPerView: 3,
				},
				300: {
					slidesPerView: 2,
				},
			},
		});

	}




	//youtube
	autoPlayYouTubeModal();


	function autoPlayYouTubeModal() {
        var trigger = $('.openVideoModal');
    	trigger.click(function () {
			
			var theModal = $(this).data("bs-target");
			var videoSRC = $(this).data("src");
			
			var videoSRCauto = videoSRC + "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
			$(theModal + ' iframe').attr('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
			$(theModal + ' iframe').attr('src', videoSRCauto);
			
			$(theModal).on('hidden.bs.modal', function (e) {
				$(theModal + ' iframe').attr('src', '');
			});

		});
	};
});